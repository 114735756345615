html {
  min-height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('/img/bg.jpg') no-repeat;
  background-size: 100%;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  padding: 20px;
}

form button, form h5 {
  margin: 20px 0;
}

.Admin {
  margin-top: 20px;
  padding: 20px;
  background: white;
}

.WalletPage {
  margin-top: 20px;
}

.WalletPage .WalletTx {
  padding: 20px;
  background: white;
}